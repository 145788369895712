const PlayerImg = (props) => {
    const { p_no, p_img } = props;
    return (
        <img
            src={`https://statiz.sporki.com/data/baseball/player/2023/${p_no}.png`}
            alt=""
            onError={(e) => {
                if (isNaN(p_img))
                    e.target.src = require(`../../../assets/images/player/${p_img}.jpg`);
                else
                    e.target.src =
                        process.env.PUBLIC_URL +
                        `/assets/images/player/kbo/${p_img}.png`;
                e.target.className = "w-full md:h-full md:w-auto bg-white";
            }}
            className="md:h-full bg-white"
        />
    );
};

export default PlayerImg;
