import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useEffect, useState } from "react";

const Playerlist = (props) => {
    const [team, setTeam] = useState(0);
    const [roster, setRoster] = useState({});
    const handleClose = () => {
        props.setShow(false);
    };
    const GUDAN = [
        "LG",
        "KT",
        "SSG",
        "NC",
        "두산",
        "KIA",
        "롯데",
        "삼성",
        "한화",
        "키움",
    ];

    const POS = ["투수", "포수", "내야수", "외야수"];

    const teamCode = (teamName) => {
        if (teamName === "SSG") return "ssg";
        else if (teamName === "삼성") return "sam";
        else if (teamName === "KT") return "kt";
        else if (teamName === "NC") return "nc";
        else if (teamName === "두산") return "doo";
        else if (teamName === "LG") return "lg";
        else if (teamName === "한화") return "han";
        else if (teamName === "KIA") return "kia";
        else if (teamName === "키움") return "kiw";
        else if (teamName === "롯데") return "lot";
        else return teamName;
    };

    useEffect(() => {
        axios
            .post("/api/kbodle/get_roster.php")
            .then((response) => {
                const result = response.data;
                setRoster(result);
            })
            .catch((error) => {
                console.error("Error fetching kbo roster:", error);
            });
    }, []);

    return (
        <>
            <Modal
                show={props.show}
                onHide={handleClose}
                centered
                className="font-family-NaBaGo"
            >
                <Modal.Header style={{ border: "none" }} closeButton>
                    <span className="font-family-kbo text-xl font-bold">
                        KBO 1군 등록 명단
                    </span>
                </Modal.Header>
                <Modal.Body className="text-sm">
                    <div className="whitespace-nowrap overflow-x-scroll py-1 border-y-2">
                        {GUDAN.map((e, i) => (
                            <div
                                className={`inline-block text-center px-3 transition  hover:text-black hover:grayscale-0 ${
                                    i === team
                                        ? "text-black grayscale-0"
                                        : "text-gray-300 grayscale"
                                }`}
                                key={e}
                                onClick={() => setTeam(i)}
                            >
                                <img
                                    src={require(`../../../assets/images/logos/${teamCode(
                                        e
                                    )}-logo.svg`)}
                                    className="h-8 w-8 sm:h-12 sm:w-12"
                                    alt={e}
                                />
                                <span className="mt-1 h-2">{e}</span>
                            </div>
                        ))}
                    </div>
                    {roster && roster.status === 200 && (
                        <div className="flex flex-col">
                            {POS.map((e, i) => (
                                <div className="mt-3">
                                    <span className="font-family-kbo bg-slate-600 rounded-md px-3 py-1 text-white">
                                        {e}
                                    </span>
                                    <ul className="mt-2">
                                        {roster[GUDAN[team]][i].map((e) => (
                                            <li className="float-left mr-2">
                                                {e}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}

                            <div className="mt-5 text-gray-700">
                                <span>• {roster.date} 기준</span>
                                <br />
                                <span>
                                    • 정보 출처:{" "}
                                    <a
                                        className="text-blue-600 hover:underline hover:underline-offset-2"
                                        href="https://www.koreabaseball.com/Player/Register.aspx"
                                    >
                                        KBO 선수 등록 현황
                                    </a>
                                </span>
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Playerlist;
